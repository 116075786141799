@import "../../assets/styles/utils";
header {
    position: relative;
    .top {
        width: 100%;
        @include res(height,120px);
        background: #fff;
        position: relative;
        .container {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            .logo {
                @include res(margin-top,10px);
                @include res(width,222px,(lg:180px,sm:130px));
            }
            .find {
                display: flex;
                align-items: center;
                @include res(width,360px,(lg:320px,mmd:280px,sm:fit-content));
                a {
                    @include res(display,flex,(sm:none));
                    align-items: center;
                    justify-content: center;
                    @include res(width,160px,(4 / 5));
                    @include res(height,50px,(4 / 5));
                    background-color: #01053b;
                    border-radius: 6px;
                    @include res(font-size,18px,(4 / 5));
                    color: #ffffff;
                    @include res(margin-right,30px,(4 / 5));
                }
                img {
                    @include res(width,170px,(lg:140px,sm:100px));
                }
            }
            .search {
                @include res(display,flex,(sm:none));
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                top: 50%;
                transform: translateY(-50%);
                @include res(width,420px,(mmd:30%));
                @include res(height,50px);
                border: solid 1px #bfbfbf;
                border-radius: 25px;
                align-items: center;
                input {
                    display: block;
                    @include res(width,360px,(mmd:85%));
                    height: 26px;
                    border: none;
                    outline: none;
                    @include res(padding-left,30px,(4 / 5));
                    @include res(font-size,16px,(14 / 16));
                    color: #c5c5c5;
                    background: transparent;
                    border-right:1px solid #dcdcdc;
                    box-sizing: border-box;
                    &::placeholder {
                        @include res(font-size,16px,(14 / 16));
                        color: #c5c5c5;
                    }
                }
                a {
                    @include res(margin-left,18px);
                }
            }
        }
    } 
    .bottom {
        width: 100%;
        @include res(height,40px,(sm:17px));
        background-color: #c21010;
        .container {
            @include res(padding-left,0);
            height: 100%;
            @include res(display,flex,(sm:none));
            align-items: center;
            justify-content: space-between;
            .nav {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                li {
                    height: 100%;
                    position: relative;
                    a {
                        font-family: D-DIN-Bold;
                        @include res(font-size,20px,(16 / 20));
                        color: #ffffff;
                        @include res(padding-left,35px);
                        @include res(padding-right,35px);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: all .3s;
                        height: 100%;
                        &.active,&:hover {
                            background: #01053B; 
                        }
                    }
                    .xiala {
                        display: none;
                        position: absolute;
                        height: auto;
                        top: 40px;
                        z-index: 9999;
                        left: 0;
                        width: 100%;
                        background: #FFFFFF;
                        @include res(padding-top,20px);
                        @include res(padding-bottom,20px);
                        a {
                            font-family: D-DIN;
                            padding: 0;
                            text-align: center;
                            font-size: 14px;
                            color: #909090;
                            transition: all .3s;
                            width: fit-content;
                            margin: auto;
                            border-bottom: 1px solid transparent;
                            margin-bottom: 8px;
                            margin-bottom: 10px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            &:hover {
                                color: #c21010;
                                border-color: #c21010;
                                background: transparent;
                            }
                        }
                    }
                }
                
            }
            .href {
                display: flex;
                align-items: center;
                a {
                    .img2 {
                        display: none;
                    }
                    &:hover {
                        .img1 {
                            display: none;
                        }
                        .img2 {
                            display: block;
                        } 
                    }
                    @include res(margin-right,22px,(2 / 3));
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .select {
        width: 100%;
        height: 420px;
        background-color: #ffffff;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
        display: none;
        position: absolute;
        @include res(top,160px);
        left: 0;
        z-index: 100;
        .left {
            width: 50%;
            display: flex;
            align-items: center;
            @include res(padding-top,47px);
            ul {
                display: flex;
                @include res(margin-left,2.28rem,(lg:10%));
                li {
                    dl {
                        @include res(margin-bottom,35px);
                        dt {
                            font-family: D-DIN-Bold;
                            @include res(font-size,16px);
                            color: #5e5e5e;
                            margin-bottom: 15px;
                        }
                        dd {
                            font-size: 14px;
                            color: #909090;
                            transition: all .3s;
                            width: fit-content;
                            border-bottom: 1px solid transparent;
                            margin-bottom: 8px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            &:hover {
                                color: #c21010;
                                border-color: #c21010;
                            }
                        }
                    }
                    &:nth-child(1) {
                        @include res(margin-right,0.83rem,(mmd:5%));
                    }
                    &:nth-child(2) {
                        @include res(margin-right,0.8rem,(mmd:5%));
                    }
                    &:nth-child(3) {
                        @include res(margin-right,0.28rem,(mmd:2%));
                    }
                }
                
            }
        }
        .right {
            width: 50%;
            img {
                @include res(margin-left,2.3rem);
                @include res(margin-top,0.46rem);
                @include res(max-width,267px);
                @include res(max-height,353px);
                display: none;
                &.active {
                    display: block;
                }
            }
        }
        &.active {
            display: flex;
        }
    }
    .open-menu {
        @include res(display,none,(sm:block));
        position: absolute;
        z-index: 100;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        &.active {
            position: fixed;
        }
    }
    .mob-nav {
        width: 100%;
        height: 100vh;
        background-color: #202020;
        position: fixed;
        top: 0;
        right: 100%;
        z-index: 99;
        color: #fff;
        box-sizing: border-box;
        overflow-y: auto;
        transition: .4s cubic-bezier(.77,0,.175,1);
        padding-top: 180px;
        .nLi {
            width: fit-content;
            margin: auto;
            margin-bottom: 30px;
            &.active {
                img {
                    transform: rotate(90deg);
                }
            }
            h3 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 20px;
                font-size: 36px;
                color: #fff;
                width: fit-content;
                font-family: D-DIN-Bold;
                
                // padding: 0 20px;
                .arrow {
                    width: 45px;
                    display: flex;
                    justify-content: end;
                }
                a {
                    flex-grow: 1;
                    font-size: 36px;

                }
            }
        }
        &.active {
            right: 0;
        }
        .sub {
            display: none;
            li {
                a {
                    display: block;
                    font-size: 36px;
                    color: #ffffff;
                }
            }
        }
        .function {
            width: 100%;
            margin: auto;
            margin-top: 1rem;
            .btn {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .find {
                    border-radius: 8px;
                    border: solid 1px #ffffff;
                    width: 180px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    font-size: 20px;
                    color: #ffffff;
                }
                .href {
                    width: 204px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
            .search {
                border-bottom: 1px solid #dcdcdc;
                position: relative;
                padding-bottom: 15px;
                display: none;
                input {
                    display: block;
                    border: none;
                    outline: none;
                    background: transparent;
                    font-size: 22px;
                    color: #c5c5c5;
                    width: 80%;
                    &::placeholder {
                        font-size: 22px;
                        color: #c5c5c5;
                    }
                }
                a {
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }
    }
}