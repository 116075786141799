@import "../../assets/styles/utils";
footer {
    width: 100%;
    @include res(height,440px,(xs:auto));
    background-color: #01053b;
    @include res(padding-top,64px,(1 / 2));
    box-sizing: border-box;
    position: relative;
    .top {
        .container {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            flex-wrap: wrap;
            .left {
                @include res(width,fit-content,(xs:100%));
                .logo {
                    img {
                        @include res(margin,0,(xs:auto));
                    }
                }
                .href {
                    @include res(margin-top,90px,(1 / 3));
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    a {
                        display: block;
                        @include res(width,36px);
                        @include res(height,36px);
                        border-radius: 50%;
                        overflow: hidden;
                        @include res(margin-right,14px,(10 / 14));
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
            .right {
                @include res(margin-top,0,(xs:20px));
                display: flex;
                @include res(flex-wrap,nowrap,(xs:wrap));
                @include res(width,auto,(mmd:65%,xs:100%));
                align-items: stretch;
                justify-content: space-between;
                dl {
                    @include res(margin-right,90px,(lg:80px,mmd:0));
                    &:last-child {
                        margin-right: 0;
                    }
                    dt {
                        font-family: D-DIN;
                        @include res(font-size,20px,(4 / 5));
                        letter-spacing: 1px;
                        color: #ffffff;
                        @include res(margin-bottom,25px,(2 / 3));
                    }
                    dd {
                        @include res(font-size,16px,(14 / 16));
                        color: #bfbfc9;
                        letter-spacing: 1px;
                        @include res(margin-bottom,15px,(2 / 3));
                        transition: all .3s;
                        &:hover {
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
    .bottom {
        @include res(position,absolute,(xs:relative));
        bottom: 0;
        left: 0;
        width: 100%;
        @include res(height,66px);
        border-top:1px solid rgba(255,255,255,.2);
        font-size: 14px;
        color: rgba(255,255,255,.6);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}