@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'D-DIN';
  src:url('../fonts/D-DIN.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'D-DIN-Bold';
  src: url('../fonts/D-DIN-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
  //文字中空描边
  // color: transparent;
  // -webkit-text-stroke: 1px #fff;
  // -webkit-font-smoothing: antialiased;
}
html {
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      opacity: 0.2;
      background: #AAAAAA;
  }
  &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: fade(#fff, 30%);
  }
}
body {
  font-family: 'D-DIN';
  overflow-x: hidden;
  line-height: 1.2;
  position: relative;
  color: $color-text;
  background-color: #fff;
  @include res(font-size, $text-size-base, $text-size-ratio);
}

img {
  max-width: fit-content;
  display: block;
  width: 100%;
}

//清除浮动
.clear {
  zoom: 1;

  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}


//主体内容部分宽度
.container {
  box-sizing: content-box;
  width: 100%;
  max-width: 1460px;
  @include res(padding-left, $container-gutter-base, $container-gutter-ratio);
  @include res(padding-right, $container-gutter-base, $container-gutter-ratio);

  &.container-1200 {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1600px) {
  .container {
    box-sizing: border-box;
    max-width: 1330px;
  }
}

@media screen and (max-width: 1440px) {
  .container {
    max-width: 1200px;
  }
}
@media screen and (max-width: 1024px) {
  .container {
    max-width: 960px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    max-width: 700px;
  }
}


//圆形进度条
@keyframes progress {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes progress1 {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes progress2 {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes rain {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }

  25% {
    opacity: 0.7;
    transform: translateY(-10%);
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  75% {
    opacity: 0.7;
    transform: translateY(10%);
  }

  100% {
    opacity: 0;
    transform: translateY(20%);
  }
}

.banner {
  position: relative;
  @include res(height,auto,(xs:240px));
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 100%;
  }
  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    h2 {
      font-family: D-DIN-Bold;
      @include res(font-size,66px,(2 / 3));
      color: #ffffff;
    }
  }
}
.list-nav {
  width: fit-content;
  margin: auto;
  @include res(margin-top,80px,(1 / 3));
  @include res(margin-bottom,60px,(1 / 3));
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      @include res(width,160px);
      @include res(height,36px);
      @include res(line-height,36px);
      background-color: #01053b;
      border-radius: 2px;
      a {
        display: block;
        width: 100%;
        height: 100%;
        font-family: D-DIN;
        @include res(font-size,18px,(16 / 18));
        color: #ffffff;
        text-align: center;
      }
    }
  }
}
.format {
  .more1 {
    margin: auto;
    text-align: center;
    width: fit-content;
    position: absolute;
    left: 0;
    right: 0;
    @include res(bottom,-70px,(md:-35px,xs:-10px));
}

.more1 a {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
}

.more1 a:hover img {
    display: none;
}

.more1 a:hover .spinner {
    display: block;
}

.spinner {
    width: 25px;
    height: 25px;
    position: relative;
    display: none;
}

.container1>div,
.container2>div,
.container3>div {
    width: 6px;
    height: 6px;
    background-color: #e60012;
    border-radius: 100%;
    position: absolute;
    -webkit-animation: bouncedelay 1.2s infinite ease-in-out;
    animation: bouncedelay 1.2s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.spinner .spinner-container {
    position: absolute;
    width: 100%;
    height: 100%;
}

.container2 {
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
}

.container3 {
    -webkit-transform: rotateZ(90deg);
    transform: rotateZ(90deg);
}

.circle1 {
    top: 0;
    left: 0;
}

.circle2 {
    top: 0;
    right: 0;
}

.circle3 {
    right: 0;
    bottom: 0;
}

.circle4 {
    left: 0;
    bottom: 0;
}

.container2 .circle1 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.container3 .circle1 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

.container1 .circle2 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.container2 .circle2 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

.container3 .circle2 {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}

.container1 .circle3 {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}

.container2 .circle3 {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

.container3 .circle3 {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}

.container1 .circle4 {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}

.container2 .circle4 {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}

.container3 .circle4 {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}

@keyframes bouncedelay {
    0%,
    80%,
    100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }
    40% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}

}