@import "../../assets/styles/utils";
.page {
    width: fit-content;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @include res(padding-bottom,100px,(1 / 3));
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        @include res(width,50px,(2 / 3));
        @include res(height,50px,(2 / 3));
        border: solid 1px #7d7d7d;
        border-radius: 2px;
        @include res(font-size,20px,(16 / 20));
        color: #202020;
        transition: all .3s;
        margin-right: 10px;
        &:hover,&.active {
            background-color: #c21010;
            border-color: #c21010;
            color: #fff;
        }
        &:last-child {
            margin-right: 0;
        }
        &:first-child,&:last-child {
            span {
                @include res(font-size,30px,(4 / 5));
            }
        }
    }
}