@font-face {
  font-family: "iconfont"; /* Project id 4104917 */
  src: url('iconfont.woff2?t=1685949602638') format('woff2'),
       url('iconfont.woff?t=1685949602638') format('woff'),
       url('iconfont.ttf?t=1685949602638') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cc-left:before {
  content: "\e6c2";
}

.icon-cc-right:before {
  content: "\e6c4";
}

.icon-swap-right:before {
  content: "\e959";
}

